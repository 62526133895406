import { useEffect } from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { getBaseUrl } from "../utils";
import axios from "axios";
import { useState } from "react";
const Terms_Conditions = () => {
  const [setting, setsetting] = useState({});
  function getsettingData() {
    axios
        .get(getBaseUrl() + "user_api/setting")
        .then((res) => {
            console.log("res--setting-->", res.data);
            if (res.data.status)
            setsetting(res.data.results);
        });
}
  useEffect(() => {
    getsettingData();
    window.scrollTo(0, 0);
}, []);
  return (
    <div>
      <Navbar />
      <section className="Services_section_maain">
        <div className="container-xl Services_section">
          <div className="row">
            <div className="col-12">
              <h1>Terms & Conditions</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="text_section">
        <div className="container-xl">
          <div className="row">
            <div className="col-12">
            <div dangerouslySetInnerHTML={{ __html: setting.terms_of_service}} />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default Terms_Conditions;
