import { Link } from "react-router-dom";
import "../App.css";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import ApartmentIcon from '@mui/icons-material/Apartment';
import { getBaseUrl } from "../utils";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
function MyVerticallyCenteredModal(props) {
  return (
    <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    dialogClassName="custom-modal-shadow"
  >
    <Modal.Header className="text-center">
      <div style={{ width: '100%' }}>
        <h1>Details Submitted</h1>
      </div>
    </Modal.Header>
    <Modal.Body style={{ backgroundColor: '#ffffff' }} className="text-center">
     <img src="/Images/Images provided by Bhaskar ji/submited.png" alt="submited" />
     <h5>Your details has been successfully submitted.</h5>
    </Modal.Body>
    <Modal.Footer className="justify-content-center">
      <Button onClick={props.onHide} style={{width:'30%',height:'50px',background:'#FBAE16',border:'none'}}>Go Back Home</Button>
    </Modal.Footer>
  </Modal> 
  );
}
const Footer = () => {
  const [modalShow, setModalShow] = useState(false);
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setemail] = useState("");
  const [number, setnumber] = useState("");
  const [message, setmessage] = useState("");
  function handalSubmit() {
    // console.log("firstName------>",firstName);
    if (!firstName) {
      alert("Name field is required");
      return false;
    }
    if (!lastName) {
      alert("lastName field is required");
      return false;
    }
    if (!email) {
      alert("email field is required");
      return false;
    }
    if (!number) {
      alert("number field is required");
      return false;
    }
    if (!message) {
      alert("message field is required");
      return false;
    }
    axios
      .post(getBaseUrl() + "user_api/contactSubmit", {
        firstName: firstName,
        lastName: lastName,
        email: email,
        number: number,
        message: message,
      })
      .then((res) => {
        console.log("res---->", res.data);
        if (res.data.status)
           //alert(res.data.message);
          //navigate('/Thank', { state: {} });
          setfirstName("");
          setlastName("");
          setemail("");
          setnumber("");
          setmessage("");
          setModalShow(true)
       // window.scrollTo(0, 0);
      });
  }
  const [setting, setsetting] = useState({});
  function getsettingData() {
    axios
        .get(getBaseUrl() + "user_api/setting")
        .then((res) => {
            console.log("res--setting-->", res.data);
            if (res.data.status)
            setsetting(res.data.results);
        });
}
  useEffect(() => {
    getsettingData();
}, []);

  return (
    <>
      {/* Contact _section start */}
      <section className="Containe_section_top_main">
        <div className="container-xl">
          <div className="row">
            <div className="col-12 Contat_main_section_footer">
              <div className="row"id='get_In_Touch'>
                <div className="col-2 Images_section_contact">
                  <img src="/Images/Footer_contact.png" alt="images" />
                </div>
                <div className="col-10 Contact_form">
                  <h1>Get In Touch</h1>
                  <div className="Form_section">
                    <div className="Form_name_section">
                      <div className="Name_section">
                        <label>
                          First Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                        onChange={(e) => { setfirstName(e.target.value) }} 
                        value={firstName}
                          type="text"
                          name="name"
                          required
                          placeholder="First Name"
                        />
                      </div>
                      <div className="Name_section">
                        <label>
                          Last Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                        onChange={(e) => { setlastName(e.target.value) }} 
                        value={lastName}
                          type="text"
                          name="name"
                          required
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                    <div className="Form_name_section">
                      <div className="Name_section">
                        <label>
                          Email <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                        onChange={(e) => { setemail(e.target.value) }} 
                        value={email}
                          type="email"
                          name="email"
                          required
                          placeholder="Example@gmail.com"
                        />
                      </div>
                    </div>
                    <div className="Form_name_section">
                      <div className="Name_section">
                        <label>
                          Phone <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                        onChange={(e) => { setnumber(e.target.value) }} 
                        value={number}
                          type="number"
                          name="number"
                          required
                          placeholder="(XXX) XXXX XXX"
                        />
                      </div>
                    </div>
                    <div className="Form_name_section">
                      <div className="Name_section">
                        <label>Message</label>
                        
                        <textarea onChange={(e) => { setmessage(e.target.value) }} value={message}  placeholder="Message"></textarea>
                      </div>
                    </div>

                   <center> <div className="Submit" onClick={() => { handalSubmit() }}>
                      Submit <NorthEastIcon className="NorthEastIcon" />{" "}
                    </div></center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <MyVerticallyCenteredModal
          show={modalShow} 
          onHide={() => setModalShow(false)}  
        />

      
      {/* Footer coding start */}

      <section className="Footer_Main">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-5 Footer_column">
              <div className="About_footer_section">
                <div className="About_Footer_logo">
                  <img src="/Images/Logo.png" alt="Footer_logo" />
                  <p>
                    Riana Towers is not just a building; it's a lifestyle.
                    Nestled in the heart of Noida, our iconic tower offers a
                    harmonious blend of contemporary design, luxurious
                    amenities, and unparalleled convenience.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 Footer_column">
              <div className="Contact_footer_section">
                <h1>Contact</h1>
                <div className="Fa_icons_section_footer" style={{display:'flex',flexDirection:'column'
                }}>
                  <div style={{display:'flex',alignItems:'center',gap:'10px'
                }}>
                    <i class="las la-phone-volume"></i>  <p style={{padding:'0',margin:'0'}}>{setting.contact_number}</p>
                  </div>

                  <p>  Call Between 10 AM to 7 PM</p>
                </div>
                <div className="Fa_icons_section_footer">
                  <div>
                    <i class="lar la-envelope"></i>
                  </div>
                  <div className="text_section_contact">
                    <p>{setting.contact_gmail}</p>
                  </div>
                </div>
                <div className="Fa_icons_section_footer">
                  <div className="text_section_contact">
                  <ApartmentIcon />  {setting.contact_address}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 Footer_column">
              <div className="Follow_section_Footer">
                <h1>Follow Us</h1>
                <div className="Fa_section">
                  <i onClick={() => window.open(setting.facebook_link, "_blank")} class="lab la-facebook"></i>
                  <i onClick={() => window.open(setting.twitter_link, "_blank")} class="lab la-twitter"></i>
                  <i onClick={() => window.open(setting.instagram_link, "_blank")} class="lab la-instagram"></i>
                </div>
              </div>
              <div className="footer_images">
                <Link to="/our_blog">
                  <p>Blogs</p>
                </Link>
                <Link to="/terms_conditions">
                  <p>Terms & Conditions</p>
                </Link>
                <Link to="/privacy_policy">
                  <p>Privacy Policy</p>
                </Link>
              </div>
            </div>

            <div className="col-12 copy_right_section">
              <p>© 2022 Dandelion | All Rights Reserved</p>
            </div>
          </div>
        </div>
      </section>
      {/* end footer coding  */}
    </>
  );
};
export default Footer;
