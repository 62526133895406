import { useEffect, useState } from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { getBaseUrl } from "../utils";
import axios from "axios";

const About = () => {
  useEffect(() => {
    getteamData()
    window.scrollTo(0, 0);
  }, []);
  function getteamData() {
    axios
      .post(getBaseUrl() + "user_api/teamList")
      .then((res) => {
        console.log("res---->", res.data);
        if (res.data.status)
          setteam(res.data.results);
      });
  }
  const [teamData, setteam] = useState([]);

  return (
    <div>
      <Navbar />
      <section className="About_section_main_all">
        <div className="container-xl">
          <div className="row About_section_ttete">
            <div className="col-md-6 About_section_text">
              <h1>Your Workplace, Reimagined.</h1>
              <p>
                Our Co-working Space Riana is more than just a workspace; it's a
                vibrant community of innovators, entrepreneurs, and
                professionals.
              </p>
            </div>
            <div className="col-md-6 About_section_images">
              <img
                src="/Images/Images provided by Bhaskar ji/_MG_9751_2_3_fused_1.png"
                alt="images"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="About_section_main_secound">
        <div className="container-xl About_section_secound">
          <div className="row">
            <div className="col-12">
              <h1>About us</h1>
              <p>
                We offer a range of flexible membership plans to suit your
                needs, whether you're a freelancer, a small business owner, or a
                large corporation. Our spacious, well-designed workspaces,
                high-speed internet, and modern amenities create an inspiring
                atmosphere that fosters innovation. Beyond providing a physical
                workspace, we strive to foster a sense of community. Our regular
                events, workshops, and networking opportunities connect
                like-minded individuals and help you build valuable relatio
              </p>
            </div>
            <div className="Expreanse_section_main">
                <div className="Exprteans_section">
                  <div className="Icons_Expreansces">
                    <img src="/Images/tactic.png" alt="images" />
                    <h1>500+</h1>
                  </div>
                  <p>Projects</p>
                </div>
                <div className="Exprteans_section">
                  <div className="Icons_Expreansces">
                    <img src="/Images/kid_star.png" alt="images" />
                    <h1>150+</h1>
                  </div>
                  <p>Happy Clients</p>
                </div>
                <div className="Exprteans_section">
                  <div className="Icons_Expreansces">
                    <img src="/Images/Qustion.png" alt="images" />
                    <h1>24/7</h1>
                  </div>
                  <p>Support Help</p>
                </div>
              </div>
          </div>
        </div>
      </section>

      <section className="About_section_thard">
        <div className="container-xl About_section_th">
          <div className="row">
            <div className="col-md-6">
              <h1>Our Vision</h1>
              <p>
                At Vision, we're redefining the traditional workspace. Our
                co-working spaces are more than just offices; they're vibrant
                hubs of innovation and creativity. Immerse yourself in a dynamic
                environment that fosters collaboration, productivity, and
                professional growth. From inspiring design and state-of-the-art
                amenities to a supportive community of like-minded individuals,
                Vision offers everything you need to thrive.
              </p>
            </div>
            <div className="col-md-6">
              <img
                src="/Images/Images provided by Bhaskar ji/About_2.png"
                alt="images"
              />
            </div>
          </div>
        </div>
        <div className="container-xl About_section_th">
          <div className="row">
            <div className="col-md-6">
              <img
                src="/Images/Images provided by Bhaskar ji/About_3.png"
                alt="images"
              />
            </div>
            <div className="col-md-6">
              <h1>Our Mission</h1>
              <p>
                To foster a thriving community of innovators and entrepreneurs
                by providing inspiring workspaces, essential resources, and
                opportunities for collaboration. We aim to create a dynamic
                environment where individuals and businesses can connect, share
                ideas, and grow together.
              </p>
            </div>
          </div>
        </div>
        <centre>
          <div className="container-xl About_images_sectionsss">
            <div className="row">
              <div className="col-12 Images_section">
                <img src="/Images/Images_About.png" alt="images" />
              </div>
            </div>
          </div>
        </centre>
      </section>

      <section className="Our_teams_section_main">
        <div className="container-xl Our_team_section_main">
          <div className="row">
            <div className="col-12">
              <h1>Meet Our Team</h1>
              <div className="row">
              {teamData.map((eachData) => {
              return (
                <>
                <div className="col-md-4">
                  <div className="Images_section">
                    <div className="Images_section_all">
                      <img src={eachData.img}alt="images" />
                      <div className="Over_section_main">
                        <h5>{eachData.name}</h5>
                        <p>{eachData.designation}</p>
                        <div className="Icons_section">
                          <i
                            class="fa fa-facebook-official"
                            aria-hidden="true"
                          ></i>
                          <i class="fa fa-instagram" aria-hidden="true"></i>
                          <i class="fa fa-twitter" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <p className="Out_tem_info">
                  <div dangerouslySetInnerHTML={{ __html: eachData.description}} />
                  </p>
                </div>
                </>
              );
            })}
                {/* <div className="col-md-4">
                  <div className="Images_section">
                    <div className="Images_section_all">
                      <img src="/Images/Images provided by Bhaskar ji/fixed-height sec- (1).png" alt="images" />
                      <div className="Over_section_main">
                        <h5>Mr. Bhupendra Singh</h5>
                        <p>Sr. Manager Accounts</p>
                        <div className="Icons_section">
                          <i
                            class="fa fa-facebook-official"
                            aria-hidden="true"
                          ></i>
                          <i class="fa fa-instagram" aria-hidden="true"></i>
                          <i class="fa fa-twitter" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <p className="Out_tem_info">
                  He is a Senior Manager Accounts at Riana Towers. Who is a detail-oriented professional with core strengths in Financial Planning and Analysis, Accounts & Audit, Income Tax Matters, Company Law, GST, VAT, Compliances and Profit Centre operations. Offering over 10+ years of invaluable experience in all Finance disciplines with key skills in finance management, negotiations, cash management, account reconciliation, creditor/debtor management, Vendor and investment operations.
                  </p>
                </div> */}

              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default About;
