import Navbar from "./Navbar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import Footer from "./Footer";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import { useEffect } from "react";

import { getBaseUrl } from "../utils";
import axios from "axios";
import { useState } from "react";

const Partnership = () => {


  

  function gethomeData() {
    axios
      .get(getBaseUrl() + "user_api/homeData2")
      .then((res) => {
        console.log("res---->", res.data);
        if (res.data.status)
          settestimonlsData(res.data.testimonls);
        setbrandData(res.data.brandData);


      });
  }
  const [testimonlsData, settestimonlsData] = useState([]);
  const [brandData, setbrandData] = useState([]);


  useEffect(() => {
    window.scrollTo(0, 0);
    gethomeData()
  }, []);
  return (
    <div>
      <Navbar />
      <section className="About_section_main_all">
        <div className="container-xl">
          <div className="row About_section_ttete">
            <div className="col-md-6 About_section_text">
              <h1>Partnering for Success.</h1>
              <p>
                A partnership is a formal arrangement between two or more
                parties to manage and operate a business and share its profits
                and liabilities.
              </p>
              <button>
                Partnership with us{" "}
                <NorthEastIcon className="ArrowRightAltIcon" />{" "}
              </button>
            </div>
            <div className="col-md-6 About_section_images">
              <img
                src="/Images/Images provided by Bhaskar ji/Images_partner_1.png"
                alt="images"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="About_section_thard">
        <div className="container-xl About_section_th">
          <div className="row">
            <div className="col-md-6">
              <img
                src="/Images/Images provided by Bhaskar ji/Images_partner.png"
                alt="images"
              />
            </div>
            <div className="col-md-6">
              <h1 style={{ paddingBottom: "0" }}>Innovative Marketing</h1>
              <p style={{ paddingTop: "0" }}>
                With our expertise in digital marketing and property promotion,
                we’ll showcase your spaces to a broad audience, attracting the
                right tenants and maximizing your property’s potential. Get
                ready to see your spaces shine!
              </p>
            </div>
          </div>
        </div>
        <div className="container-xl About_section_th">
          <div className="row">
            <div className="col-md-6">
              <h1 style={{ paddingBottom: "0" }}>Expert Support</h1>
              <p style={{ paddingTop: "0" }}>
                Our dedicated team is here to support you every step of the way.
                From initial consultations to ongoing management, we provide the
                resources and guidance you need to make your leasing experience
                smooth and successful.
              </p>
            </div>
            <div className="col-md-6">
              <img
                src="/Images/Images provided by Bhaskar ji/Images_partner2.png"
                alt="images"
              />
            </div>
          </div>
        </div>
      </section>

      <div className="container-xl Brand_partner">
        <div className="row">
          <div className="col-md-8 Testmonial_section_main">
            <h1>Our Brand Partner</h1>
            <p>
              Committed to fostering collaboration, creativity, and
              productivity, our partner offers a diverse range of workspace
              solutions, including:
            </p>
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
      {/* <section className="Slider_full_section">
        <div className="container-xl Brand_partner_slider">
          <div className="row">
            <div className="col-12">
              <div id="infinite" class="highway-slider">
                <div class="container-fluid highway-barrier">
                  <ul class="highway-lane">
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/Brand1.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/Brand2.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/brean3.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/brand4.png"
                        alt="images"
                      />
                    </li>
                   

                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/Brand1.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/Brand2.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/brean3.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/brand4.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/Brand1.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/Brand2.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/brean3.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/brand4.png"
                        alt="images"
                      />
                    </li>
                   
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="Slider_full_section">
        <div className="container-xl Brand_partner_slider">
          <div className="row">
            <div className="col-12">
              <div id="infinite" class="highway-slider">
                <div class="container-fluid highway-barrier">
                  <ul class="highway-lane">
                    {brandData.map((eachData) => {
                      return (
                        <>
                          <li class="highway-car">
                            <img
                              src={eachData.img}
                              alt="images"
                            />
                          </li>

                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* new setion start INDUSTRY BASED VALUE  */}
      <section className="INDUSTRY_BASED_VALUE">
        <div className="container-xl Industry_value_section">
          <div className="row">
            <div className="col-md-4">
              <div className="Industry_value">
                <p>
                  1.
                   Brand Reputation: A well-established brand reputation can
                  attract customers and command premium prices.  
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="Industry_value">
                <p>
                  2.
                   Intellectual Property: Patents, trademarks, and copyrights can
                  provide a competitive edge and increase a company's value.  
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="Industry_value">
                <p>
                  3. 
                   Customer Relationships: Strong relationships with customers
                  can lead to repeat business, referrals, and increased customer
                  loyalty.  
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Start testimonial section  */}
      <div className="container-xl">
        <div className="row">
          <div className="col-md-8 Testmonial_section_main">
            <h1>They Satisfied with service</h1>
            <p>
              For our staff, our co-working space has changed everything. The
              contemporary amenities, adaptable workstations, and encouraging
              community have produced the perfect setting for cooperation and
              production.
            </p>
          </div>
          <div className="col-md-4">
            <div className="Testimonial_images">
              <img src="/Images/Testimonial_header_img.png" alt="images" />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-xl">
        <div className="row">
          <div className="col-12">
            <Swiper
              spaceBetween={30}
              slidesPerView={2}
              speed={3000}
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
                loop: true,
              }}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                100: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                620: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                720: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
              }}
              modules={[Pagination, Autoplay]}
              className="mySwiper"
            >
              <SwiperSlide className="slider_main">
                <div className="Testimonial_section_slider">
                  <div className="Testimonial_section_slider_items">
                    <p>
                      Led by visionary architects of efficiency, managed spaces
                      embody comfort, innovation, and ease—each element crafted
                      for harmony.
                    </p>

                    <div className="Testimonial_name">
                      <div className="Testimonil_images">
                        <img
                          src="/Images/Images provided by Bhaskar ji/Testimonial2.png"
                          alt="images"
                        />
                      </div>
                      <div className="Testimonial_name_heading">
                        <h5>Sukhdev Pathak</h5>
                        <p>CEO & Founder</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="slider_main">
                <div className="Testimonial_section_slider">
                  <div className="Testimonial_section_slider_items">
                    <p>
                      Driven by pioneers in efficiency, managed spaces blend
                      comfort, innovation, and ease—every detail designed for
                      perfect harmony.
                    </p>

                    <div className="Testimonial_name">
                      <div className="Testimonil_images">
                        <img
                          src="/Images/Images provided by Bhaskar ji/Testimonial.png"
                          alt="images"
                        />
                      </div>
                      <div className="Testimonial_name_heading">
                        <h5>Nirmala S. Karthik</h5>
                        <p>Manager</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="slider_main">
                <div className="Testimonial_section_slider">
                  <div className="Testimonial_section_slider_items">
                    <p>
                      Riana Towers transformed our office into a modern,
                      inspiring workspace. Their team of experts handled every
                      detail, from furniture selection to IT setup. We're more
                      productive than ever.
                    </p>

                    <div className="Testimonial_name">
                      <div className="Testimonil_images">
                        <img
                          src="/Images/Images provided by Bhaskar ji/Testimonial1.png"
                          alt="images"
                        />
                      </div>
                      <div className="Testimonial_name_heading">
                        <h5>Prisha Aggarwal</h5>
                        <p>MD & CEO</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div> */}
      <div className="container-xl">
        <div className="row">
          <div className="col-12">
            <Swiper
              spaceBetween={30}
              slidesPerView={2}
              speed={3000}
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
                loop: true,
              }}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                100: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                620: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                720: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
              }}
              modules={[Pagination, Autoplay]}
              className="mySwiper"
            >
              {testimonlsData.map((eachData) => {
                return (
                  <>
                    <SwiperSlide className="slider_main">
                      <div className="Testimonial_section_slider">
                        <div className="Testimonial_section_slider_items">
                          <p>
                            {eachData.feedback}
                          </p>

                          <div className="Testimonial_name">
                            <div className="Testimonil_images">
                              <img
                                src={eachData.img}
                                alt="images"
                              />
                            </div>
                            <div className="Testimonial_name_heading">
                              <h5>{eachData.user_name}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default Partnership;
