import Navbar from "./Navbar";
import axios from "axios";
import "../App.css";
import "../Style.css";
import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Autoplay } from "swiper/modules";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { getBaseUrl } from "../utils";
import { useRef } from "react";


const Slide = (props) => {
  return (
    <React.Fragment>
      <img src={props.image.link} alt="Sliderr_image" />
      <h1>
        {props.image.title}
        <span className="Button_Exp_slider">
          <button onClick={props.slidePrev}>
            <KeyboardBackspaceIcon className="KeyboardBackspaceIcon" />
          </button>
          <button onClick={props.slideNext}>
            <ArrowRightAltIcon className="ArrowRightAltIcon" />
          </button>
        </span>
      </h1>
    </React.Fragment>
  );
};
const Layout = () => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prev) => {
        return prev + 1 === slides.length ? 0 : prev + 1;
      });
    }, 4000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const slides = [
    {
      id: 1,
      link: "/Images/Images provided by Bhaskar ji/About_slider_2.png",
      ImagesIcon: "ApartmentIcon",
      setting: "15",
      cabin: "4",
    },
    {
      id: 2,
      link: "/Images/Images provided by Bhaskar ji/About_slider1.png",
      ImagesIcon: "ApartmentIcon",
      setting: "315",
      cabin: "44",
    },
    {
      id: 3,
      link: "/Images/Images provided by Bhaskar ji/About_slider_3.png",
      ImagesIcon: "ApartmentIcon",
      setting: "215",
      cabin: "78",
    },
  ];
  const slideNext = (e) => {
    setCurrentSlide((prev) => {
      return prev + 1 === slides.length ? 0 : currentSlide + 1;
    });
  };
  const slidePrev = (e) => {
    setCurrentSlide((prev) => {
      return prev === 0 ? slides.length - 1 : currentSlide - 1;
    });
  };
  useEffect(() => {
    gethomeData();
    window.scrollTo(0, 0);
  }, []);

  function gethomeData() {
    axios.get(getBaseUrl() + "user_api/homeData").then((res) => {
      console.log("res---->", res.data);
      if (res.data.status) settestimonlsData(res.data.testimonls);
      setbanner(res.data.banner);
      setfaq(res.data.faq);
      setblog(res.data.latestBlog);
      setbrandData(res.data.brandData);
      setCategoryService(res.data.CategoryService);
    });
  }
  const [testimonlsData, settestimonlsData] = useState([]);
  const [CategoryService, setCategoryService] = useState([]);

  const [bannerData, setbanner] = useState([]);
  const [faqData, setfaq] = useState([]);
  const [blogData, setblog] = useState([]);
  const [brandData, setbrandData] = useState([]);

  const [activeKey, setActiveKey] = useState(null);
  const toggleAccordion = (key) => {
    setActiveKey((prevKey) => (prevKey === key ? null : key));
  };
  const swiperRef = useRef(null);

useEffect(() => {
  if (swiperRef.current && swiperRef.current.autoplay) {
    swiperRef.current.autoplay.start();
  }
}, []);
useEffect(() => {
  console.log("Page loaded, Swiper is ready");
}, []);
  const navigate = useNavigate();

  return (
    <div>
      <Navbar />

      <Swiper
  spaceBetween={0}
  slidesPerView={1}
  loop={bannerData.length > 1} // Enable loop only if there are enough slides
  autoplay={{
    delay: 3000,
    disableOnInteraction: false,
  }}
  pagination={{
    clickable: true,
  }}
  speed={1000}
  modules={[Pagination, Autoplay]}
  className="mySwiper"
>
  {/* Map through bannerData */}
  {bannerData.map((eachData, index) => (
    <SwiperSlide key={`slide-${index}`}> {/* Ensure unique keys */}
      <div className="slider_section_main">
        <div className="media_container">
          {eachData.type === "Image" ? (
            <img
              src={eachData.img}
              alt={`banner-${index}`}
              style={{ height: "100vh", width: "100%", objectFit: "cover" }}
            />
          ) : eachData.type === "Video" ? (
            <video
              autoPlay
              loop
              muted
              playsInline
              style={{
                width: "100%",
                height: "100vh",
                objectFit: "cover",
              }}
              className="video_section"
            >
              <source src={eachData.img} type="video/mp4" />
            </video>
          ) : null}
        </div>
        
        <div className="text_overlay">
          <div className="container-xl">
            <div className="row">
              <div className="col-12 Hero_section_main_hero">
                <h1>{eachData.title}</h1>
                <div
                  style={{ textAlign: "left" }}
                  dangerouslySetInnerHTML={{
                    __html: eachData.description,
                  }}
                  className="Heading_hero_top_section"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SwiperSlide>
  ))}
</Swiper>


      <section className="About_section_main">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-6 Expreance_text_section">
              <h1>Passionate About Making A Positive Impact</h1>
              <p>
                The purpose of our co-working environment is to encourage
                innovation, teamwork, and efficiency. We provide a contemporary,
                motivating setting with cutting-edge features like conference
                areas, cozy workstations, and fast internet
              </p>
              <div className="Expreanse_section_main">
                <div className="Exprteans_section">
                  <div className="Icons_Expreansces">
                    <img src="/Images/tactic.png" alt="images" />
                    <h1>500+</h1>
                  </div>
                  <p>Projects</p>
                </div>
                <div className="Exprteans_section">
                  <div className="Icons_Expreansces">
                    <img src="/Images/kid_star.png" alt="images" />
                    <h1>150+</h1>
                  </div>
                  <p>Happy Clients</p>
                </div>
                <div className="Exprteans_section">
                  <div className="Icons_Expreansces">
                    <img src="/Images/Qustion.png" alt="images" />
                    <h1>24/7</h1>
                  </div>
                  <p>Support Help</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="slider_images">
                <div className="slider_section">
                  <Slide
                    image={slides[currentSlide]}
                    slideNext={slideNext}
                    slidePrev={slidePrev}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Gallery_heding_section_main">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-6">
              <h1 className="gallery">Gallery</h1>
              <p>
                Professionals may interact, invent, and flourish in a vibrant
                and motivating environment at our co-working space.
              </p>
            </div>
          </div>
        </div>
        <div className="container-xl">
          <div className="row">
            <div className="col-12">
              <div className="accordion-slider">
                <div className="accordion">
                  <ul>
                    <li>
                      <div></div>
                    </li>
                    <li>
                      <div></div>
                    </li>
                    <li>
                      <div></div>
                    </li>
                    <li>
                      <div></div>
                    </li>
                    <li>
                      <div></div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container-xl">
        <div className="row">
          <div className="col-md-8 Testmonial_section_main">
            <h1>They Satisfied with service</h1>
            <p>
              For our staff, our co-working space has changed everything. The
              contemporary amenities, adaptable workstations, and encouraging
              community have produced the perfect setting for cooperation and
              production.
            </p>
          </div>
          <div className="col-md-4">
            <div className="Testimonial_images">
              <img src="/Images/Testimonial_header_img.png" alt="images" />
            </div>
          </div>
        </div>
      </div>
      <div className="container-xl">
        <div className="row">
          <div className="col-12">
            <Swiper
              spaceBetween={30}
              slidesPerView={2}
              speed={3000}
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
                loop: true,
              }}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                100: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                620: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                720: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
              }}
              modules={[Pagination, Autoplay]}
              className="mySwiper"
            >
              {testimonlsData.map((eachData) => {
                return (
                  <>
                    <SwiperSlide className="slider_main">
                      <div className="Testimonial_section_slider">
                        <div className="Testimonial_section_slider_items">
                          <p>{eachData.feedback}</p>

                          <div className="Testimonial_name">
                            <div className="Testimonil_images">
                              <img src={eachData.img} alt="images" />
                            </div>
                            <div className="Testimonial_name_heading">
                              <h5>{eachData.user_name}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>

      <div className="container-xl Brand_partner">
        <div className="row">
          <div className="col-md-8 Testmonial_section_main">
            <h1>Our Brand Partner</h1>
            <p>
              Committed to fostering collaboration, creativity, and
              productivity, our partner offers a diverse range of workspace
              solutions, including:
            </p>
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
      <section className="Slider_full_section">
        <div className="container-xl Brand_partner_slider">
          <div className="row">
            <div className="col-12">
              <div id="infinite" class="highway-slider">
                <div class="container-fluid highway-barrier">
                  <ul class="highway-lane">
                    {brandData.map((eachData) => {
                      return (
                        <>
                          <li class="highway-car">
                            <img src={eachData.img} alt="images" />
                          </li>
                        </>
                      );
                    })}
                    {/* <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/Brand2.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/brean3.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/brand4.png"
                        alt="images"
                      />
                    </li>
                   

                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/Brand1.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/Brand2.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/brean3.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/brand4.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/Brand1.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/Brand2.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/brean3.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/brand4.png"
                        alt="images"
                      />
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container-xl Blog_main_section">
        <div className="row">
          <div className="col-md-8 Testmonial_section_main">
            <h1>Blog Section</h1>
            <p>
              Subscribe to my blog to receive new posts directly to your inbox.
            </p>
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
      <div className="container-xl">
        <div className="row">
          <div className="col-12">
            <Swiper
              spaceBetween={30}
              slidesPerView={3}
              speed={1500}
              loop={true}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
                loop: true,
              }}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                100: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                620: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                720: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
              }}
              modules={[Pagination, Autoplay]}
              className="mySwiper"
            >
              {blogData.map((eachData) => {
                return (
                  <>
                    <SwiperSlide className="slider_main">
                      <div className="Blog_section_slider">
                        <div className="Blog_section_slider_items">
                          <div className="Blog_section_slider_Img">
                            <img src={eachData.image} alt="blog" />
                          </div>
                          <p className="Blog_header">{eachData.name}</p>
                          <p className="Dec_section_blog">
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  eachData.description.slice(0, 100) + "...",
                              }}
                            />
                          </p>
                          <Link to="/our_blog">
                            <button>
                              Read the Full Blog{" "}
                              <ArrowRightAltIcon className="ArrowRightAltIcon" />
                            </button>
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                  </>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>

      <section className="Faq_section">
        <div className="container-xl FAQ_main_section">
          <div className="row">
            <div className="col-12">
              <h1>Frequently Asked Questions</h1>
              <h5>Can’t find the answer here? Check out our Help Centre.</h5>

              <div className="accordion">
                {faqData.map((eachData) => {
                  return (
                    <>
                      <div className="accordion-item">
                        <div
                          className="accordion-header Header_section_faq"
                          onClick={() => toggleAccordion(eachData._id)}
                        >
                          <div className="Images_icons">
                            <h6>
                              {" "}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: eachData.question,
                                }}
                              />
                            </h6>
                            {activeKey === eachData._id ? (
                              <ArrowDropUpIcon className="Iconses" />
                            ) : (
                              <ArrowDropDownIcon className="Iconses" />
                            )}
                          </div>
                        </div>
                        {activeKey === eachData._id && (
                          <div className="accordion-body Body_text_section">
                            <p>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: eachData.ans,
                                }}
                              />
                            </p>
                          </div>
                        )}
                      </div>
                    </>
                  );
                })}

                {/*   
              <div className="accordion-item">
                <div
                  className="accordion-header Header_section_faq"
                  onClick={() => toggleAccordion("1")}
                >
                  <div className="Images_icons">
                    <h6>How much does a co-working space cost?</h6>
         
                    {activeKey === "1" ? (
                      <ArrowDropUpIcon className="Iconses" />
                    ) : (
                      <ArrowDropDownIcon className="Iconses" />
                    )}
                  </div>
                </div>
                {activeKey === "1" && (
                  <div className="accordion-body Body_text_section">
                    <p>
                      Pricing varies depending on the location, amenities, and
                      membership plan. Some co-working spaces offer flexible
                      memberships with daily, weekly, or monthly rates.
                    </p>
                  </div>
                )}
              </div>

           
              <div className="accordion-item">
                <div
                  className="accordion-header Header_section_faq"
                  onClick={() => toggleAccordion("2")}
                >
                  <div className="Images_icons">
                    <h6>What is included in the membership fee?</h6>
              
                    {activeKey === "2" ? (
                      <ArrowDropUpIcon className="Iconses" />
                    ) : (
                      <ArrowDropDownIcon className="Iconses" />
                    )}
                  </div>
                </div>
                {activeKey === "2" && (
                  <div className="accordion-body Body_text_section">
                    <p>
                      Membership fees typically include access to the co-working
                      space, shared amenities, high-speed internet, and basic office
                      supplies.
                    </p>
                  </div>
                )}
              </div>

           
              <div className="accordion-item">
                <div
                  className="accordion-header Header_section_faq"
                  onClick={() => toggleAccordion("3")}
                >
                  <div className="Images_icons">
                    <h6>
                      Do you offer any additional services, such as virtual office
                      plans?
                    </h6>
                 
                    {activeKey === "3" ? (
                      <ArrowDropUpIcon className="Iconses" />
                    ) : (
                      <ArrowDropDownIcon className="Iconses" />
                    )}
                  </div>
                </div>
                {activeKey === "3" && (
                  <div className="accordion-body Body_text_section">
                    <p>
                      Many co-working spaces offer additional services, such as
                      virtual office plans, mail handling, and administrative support.
                    </p>
                  </div>
                )}
              </div>

        
              <div className="accordion-item">
                <div
                  className="accordion-header Header_section_faq"
                  onClick={() => toggleAccordion("4")}
                >
                  <div className="Images_icons">
                    <h6>Can I bring my own food and drinks?</h6>
       
                    {activeKey === "4" ? (
                      <ArrowDropUpIcon className="Iconses" />
                    ) : (
                      <ArrowDropDownIcon className="Iconses" />
                    )}
                  </div>
                </div>
                {activeKey === "4" && (
                  <div className="accordion-body Body_text_section">
                    <p>
                      Most co-working spaces allow members to bring their own food
                      and drinks, but there may be restrictions on certain items.
                    </p>
                  </div>
                )}
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="About_section_main_footer">
        <div className="container-xl">
          <div className="row">
            <div className="col-12">
              <div className="About_main_footer">
                <div className="About_Footer_section">
                  <h1>About Riana Towers</h1>
                  <p>
                    Riana Towers is a prestigious commercial landmark that
                    offers world-class office spaces in Noida. Designed to
                    inspire innovation and productivity, our state-of-the-art
                    facilities provide the perfect environment for businesses of
                    all sizes.
                  </p>
                </div>
                <div className="Submit_section">
                  <Link to="/our_about">
                    <div>
                      About us <NorthEastIcon className="ArrowRightAltIcon" />{" "}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default Layout;
