import Navbar from "./Navbar";
import { getBaseUrl } from "../utils";
import axios from "axios";
import BoltIcon from "@mui/icons-material/Bolt";
import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import { useLocation, useNavigate } from "react-router-dom";

const Panel = ({ index, backgroundImage, setActivePanel, activePanel }) => {
  const handleClick = () => {
    setActivePanel(index);
  };

  return (
    <div
      className={`panel ${activePanel === index ? "active" : ""}`}
      style={{ backgroundImage: `url(${backgroundImage})` }}
      onMouseEnter={handleClick}
    >
    </div>
  );
};

const Private_Space = () => {

  const [activePanel, setActivePanel] = useState(0); 
  const [images, setimages] = useState([]); 

  const navigate = useNavigate();
  const location = useLocation();

  const [serviceID, setserviceID] = useState("");
  const [serviceData, setserviceData] = useState({});

  // Function to fetch service data
  function get_Single_ServiceData(serviceID) {
    if (!serviceID) return; // Check if serviceID is available

    console.log("Fetching service data for ID:", serviceID);
    axios
      .post(getBaseUrl() + "user_api/serviceList", {
        id: serviceID,
      })
      .then((res) => {
        console.log("API Response:", res.data);
        if (res.data.status) setserviceData(res.data.results[0]);
      })
      .catch((error) => {
        console.error("Error fetching service data:", error);
      });
  }

  function Galary(serviceID) {
    if (!serviceID) return; // Check if serviceID is available

    console.log("Fetching service data for ID:", serviceID);
    axios
      .post(getBaseUrl() + "user_api/Galary", {
        id: serviceID,
      })
      .then((res) => {
        console.log("API Galary Response:", res.data);
        if (res.data.status) setimages(res.data.results);
      })
      .catch((error) => {
        console.error("Error fetching service data:", error);
      });
  }

  // useEffect to set serviceID from location.state and fetch data
  useEffect(() => {
    window.scrollTo(0, 0);
    const { ID } = location.state ?? "";
    if (ID) {
      setserviceID(ID); // Set the service ID
    }
  }, [location]);

  // Fetch service data when serviceID changes
  useEffect(() => {
    if (serviceID) {
      get_Single_ServiceData(serviceID);
      Galary(serviceID)
    }
  }, [serviceID]);

  return (
    <div>
      <Navbar />
      <section className="Services_section_maain">
        <div className="container-xl Services_section">
          <div className="row">
            <div className="col-12">
              <h1>{serviceData.name}</h1>
              <p>
                A personal, secluded area where one can retreat to enjoy solitude or engage in private activities.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* About section coding start */}
      <section className="About_section_main">
        <div className="container-xl">
          <div className="row ">
            <div className="col-md-6 Expreance_text_section">
              <h1>Inclusive and sustainable innovation at scale.</h1>
              <p>
                Inclusive and sustainable innovation at scale means creating innovative solutions that benefit everyone, while considering the long-term environmental and social impact.
              </p>
            </div>
            <div className="col-md-6">
              <div className="slider_images">
                <div className="slider_section">
                  <img
                    src={serviceData.image}
                    alt="images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* <section className="Gallery_heding_section_main">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-6">
              <h1 className="gallery">Gallery</h1>
              <p>
                Professionals may interact, invent, and flourish in a vibrant
                and motivating environment at our co-working space.
              </p>
            </div>
          </div>
        </div>
        <div className="container-xl">
          <div className="row">
            <div className="col-12">
              <div className="accordion-slider">
                <div className="accordion">
                  <ul>
                    <li>
                      <div></div>
                    </li>
                    <li>
                      <div></div>
                    </li>
                    <li>
                      <div></div>
                    </li>
                    <li>
                      <div></div>
                    </li>
                    <li>
                      <div></div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}


      
 <section className="Gallery_heding_section_main">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-6">
              <h1 className="gallery">Gallery</h1>
              <p>
                Professionals may interact, invent, and flourish in a vibrant
                and motivating environment at our co-working space.
              </p>
            </div>
          </div>
        </div>
      </section>

  <div className="container-xl">
    <div className="row">
      <div className="col-12 Gallery_new">
        <div className="gallory_new_all">
        {images.map((image, index) => (
        <Panel
          key={index}
          index={index}
          backgroundImage={image}
          setActivePanel={setActivePanel}
          activePanel={activePanel}
         
        />
      ))}
        </div>
  
      </div>
    </div>
  
    </div>

      
      {/* Key points section */}
      <section className="Key_pointes_section">
        <div className="container-xl">
          <div className="row">
            <div className="col-12">
            <div dangerouslySetInnerHTML={{ __html:serviceData.description}} />
              {/* <h1>Key Points of Virtual spaces</h1>
              <h6>
                <BoltIcon style={{ fontSize: 30, color: "#000" }} /> Customizable Layout: Design your workspace just the way you envision it!
              </h6> */}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
export default Private_Space;
