import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Layout from "./Components/Home";
import About from "./Components/About";
import Services from "./Components/Services";
import Private_Space from "./Components/Private_Space";
import Partnership from "./Components/Partnership";
import Blog from "./Components/Blog";
import BlogDetails from "./Components/blogdetails";
import Privacy_Policy from "./Components/privacypolicy";
import Terms_Conditions from "./Components/termsconditions";
import Projects from "./Components/project";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />} />
          <Route path="our_about" element={<About />} />
          <Route path="our_services" element={<Services />} />
          <Route path="our_private_space" element={<Private_Space />} />
          <Route path="our_partnership" element={<Partnership />} />
          <Route path="our_blog" element={<Blog />} />
          <Route path="our_project" element={<Projects />} />
          <Route path="our_blog_details" element={<BlogDetails />} />
          <Route path="privacy_policy" element={<Privacy_Policy />} />
          <Route path="terms_conditions" element={<Terms_Conditions />} />

          <Route path="navbar" element={<Navbar />} />
          <Route path="footer" element={<Footer />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
