import Slider from "react-slick";
import { getBaseUrl } from "../utils";
import axios from "axios";
import { settings } from "../DataSlide/SliderData";
import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  function scrollToContact() {
    var pathname = location.pathname;
    console.log("pathname--->",pathname);
    if (pathname == '/') {
      window.scrollTo({ top: 5280, behavior: 'smooth' });
    } else {
      navigate('/', {})
      const timer = setTimeout(() => {
        window.scrollTo({ top: 5280, behavior: 'smooth' });
      }, 2000);
    }
  }
  const [affix, setAffix] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setAffix(true);
    } else {
      setAffix(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    getCategoryService()
    window.scrollTo(0, 0);
  }, []);

  function getCategoryService() {
    axios
      .get(getBaseUrl() + "user_api/CategoryService")
      .then((res) => {
        console.log("res---->", res.data);
        if (res.data.status)
          setcategoryService(res.data.results);
      });
  }
  const [categoryService, setcategoryService] = useState([]);

  //const location = useLocation();

  const getActiveClass = (path) => {
    return location.pathname === path ? "active" : "";
  };

  return (
    <>
      <div className={`navbar_main ${affix ? "affix" : ""}`}>
        <div className="container-xl">
          <div className="row">
            <nav>
              <div className="wrapper">
                <div className="logo">
                  <img src="/Images/Logo.png" alt="Logo" />
                </div>
                <input type="radio" name="slider" id="menu-btn" />
                <input type="radio" name="slider" id="close-btn" />
                <ul className="nav-links">
                  <label htmlFor="close-btn" className="btn close-btn">
                  <CloseIcon style={{ fontSize: '40px' }} />
                  </label>
                  <li className={getActiveClass("/")}>
                    <Link to="/">Home</Link>
                  </li>
                  <li className={getActiveClass("/our_about")}>
                    <Link to="/our_about">About Us</Link>
                  </li>
                  <li className={getActiveClass("/our_partnership")}>
                    <Link to="/our_partnership">Partnerships</Link>
                  </li>
                  <li>
                    <a className="desktop-item">
                      What we do
                    </a>
                    <input type="checkbox" id="showDrop" />
                    <label htmlFor="showDrop" className="mobile-item">
                      What we do
                    </label>
                   
                    <ul className="drop-menu">
                    <div className="Cout_out_section"></div>
                      <li>
                      <Link to="/our_services">
                          Service
                        </Link>
                      </li>
                      <li>
                      <Link to="/our_project">
                          Projects
                        </Link>
                        </li>
                    </ul>
                  </li>
                  <li>
                    <button  onClick={() => { scrollToContact() }} className="login-button">
                      Get In Touch
                    </button>
                  </li>
                </ul>
                <label htmlFor="menu-btn" className="btn menu-btn">
                <MenuIcon className="menu_bar" style={{ fontSize: '40px' }} />
                </label>
              </div>
            </nav>
          </div>
        </div>

        <div className="container-xl slider_section_nav">
          <div className="row">
            <div className="col-12 slider-container">
              <Slider {...settings}>
              {categoryService.map((eachData) => {
              return (
                <>
                <div className="slider_section_div">
                  <Link to="/our_services">
                    <center>
                      <img
                        src={eachData.image}
                        alt="logo images"
                      />
                      <p>{eachData.name}</p>
                    </center>
                  </Link>
                </div>
                </>
              );
            })}
                {/* <div className="slider_section_div">
                  <Link to="/our_services">
                    <center>
                      <img src="/Images/devices_fold_2.svg" alt="logo images" />
                      <p>Coworking</p>
                    </center>
                  </Link>
                </div>
                <div className="slider_section_div">
                  <Link to="/our_services">
                    <center>
                      <img src="/Images/distance.svg" alt="logo images" />
                      <p>Business address</p>
                    </center>
                  </Link>
                </div>
                <div className="slider_section_div">
                  <Link to="/our_services">
                    <center>
                      <img src="/Images/festival.svg" alt="logo images" />
                      <p>Event Spaces</p>
                    </center>
                  </Link>
                </div>
                <div className="slider_section_div">
                  <Link to="/our_services">
                    <center>
                      <img
                        src="/Images/format_letter_spacing.svg"
                        alt="logo images"
                      />
                      <p>Office Space</p>
                    </center>
                  </Link>
                </div>
                <div className="slider_section_div">
                  <Link to="/our_services">
                    <center>
                      <img src="/Images/groups.svg" alt="logo images" />
                      <p>Meeting Rooms</p>
                    </center>
                  </Link>
                </div>
                <div className="slider_section_div">
                  <Link to="/our_services">
                    <center>
                      <img
                        src="/Images/inbox_customize.svg"
                        alt="logo images"
                      />
                      <p>Custom Offices</p>
                    </center>
                  </Link>
                </div>
                <div className="slider_section_div">
                  <Link to="/our_services">
                    <center>
                      <img src="/Images/simulation.svg" alt="logo images" />
                      <p>Virtual Offices</p>
                    </center>
                  </Link>
                </div>
                <div className="slider_section_div">
                  <Link to="/our_services">
                    <center>
                      <img src="/Images/support_agent.svg" alt="logo images" />
                      <p>Telephone Answering</p>
                    </center>
                  </Link>
                </div>
                <div className="slider_section_div">
                  <Link to="/our_services">
                    <center>
                      <img src="/Images/verified_user.svg" alt="logo images" />
                      <p>Private Offices</p>
                    </center>
                  </Link>
                </div> */}

              </Slider>
            </div>
          </div>
        </div>
      </div>

      {/* end Navbar section */}
    </>
  );
};
export default Navbar;
