import Navbar from "./Navbar";
import { getBaseUrl } from "../utils";
import axios from "axios";

import NorthEastIcon from "@mui/icons-material/NorthEast";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import Footer from "./Footer";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Panel = ({ index, backgroundImage, setActivePanel, activePanel }) => {
  const handleClick = () => {
    setActivePanel(index);
  };

  return (
    <div
      className={`panel ${activePanel === index ? "active" : ""}`}
      style={{ backgroundImage: `url(${backgroundImage})` }}
      onMouseEnter={handleClick}
    >
    </div>
  );
};

const Services = () => {

  const [activePanel, setActivePanel] = useState(0); 

  const images = [
    "Images/Blog_img.png",
    "Images/Blog_img.png",
    "Images/Blog_img.png",
    "Images/Blog_img.png",
    "Images/Blog_img.png",
  ];
  
  useEffect(() => {
    window.scrollTo(0, 0);
    getServiceData();
  }, []);
  const navigate = useNavigate();

  function clickOnService(ID) {
    console.log("serviceID----home------>", ID);
    navigate('/our_private_space', { state: { ID: ID } });
  }


  function getServiceData() {
    axios
      .post(getBaseUrl() + "user_api/serviceList")
      .then((res) => {
        console.log("res---->", res.data);
        if (res.data.status)
          setserviceData(res.data.results);
      });
  }
  const [serviceData, setserviceData] = useState([]);

  return (
    <div>
      <Navbar />
      <section className="Services_section_maain">
        <div className="container-xl Services_section">
          <div className="row">
            <div className="col-12">
              <h1>Service we offer</h1>
              <p>
                Riana offers premium co-working spaces designed to
                inspire creativity and productivity. <br></br>Our spaces provide
                a perfect blend of comfort, functionality, and community's
                premium co-working spaces designed<br></br> to inspire
                creativity and productivity. Our spaces provide a perfect blend
                of comfort, functionality, and community.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="services_one_section">
        <div className="container-xl">
          <div className="row">
            <div className="col-12">
              <h1>Certified Excellence</h1>
              <p>
                Certified Excellence signifies a commitment to quality,{" "}
                <br></br>reliability, and exceptional standards.
              </p>
            </div>
            {serviceData.map((eachData) => {
              return (
                <>
            <div className="col-md-4 services_section_all">
              <div className="Services_section">
                <img
                  src={eachData.image}
                  alt="images"
                />
                {/* <Link to="/our_private_space"> */}
                  <h5>{eachData.name}</h5>
                  <button onClick={() => clickOnService(eachData._id)} >
                    <NorthEastIcon className="ArrowRightAltIcon" />
                  </button>
                {/* </Link> */}
              </div>
            </div>
            </>
              );
            })}
            {/* <div className="col-md-4 services_section_all">
              <div className="Services_section">
                <img
                  src="/Images/Images provided by Bhaskar ji/Services_2.png"
                  alt="images"
                />
                <Link to="/our_private_space">
                  <h5>Private Space</h5>
                  <button>
                    <NorthEastIcon className="ArrowRightAltIcon" />
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-md-4 services_section_all">
              <div className="Services_section">
                <img
                  src="/Images/Images provided by Bhaskar ji/services_3.png"
                  alt="images"
                />
                <Link to="/our_private_space">
                  <h5>Private Space</h5>
                  <button>
                    <NorthEastIcon className="ArrowRightAltIcon" />
                  </button>
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </section>


      {/* Gallery section coding start
      <section className="Gallery_heding_section_main">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-6">
              <h1 className="gallery">Gallery</h1>
              <p>
                Professionals may interact, invent, and flourish in a vibrant
                and motivating environment at our co-working space.
              </p>
            </div>
          </div>
        </div>
        <div className="container-xl">
          <div className="row">
            <div className="col-12">
              <div className="accordion-slider">
                <div className="accordion">
                  <ul>
                    <li>
                      <div></div>
                    </li>
                    <li>
                      <div></div>
                    </li>
                    <li>
                      <div></div>
                    </li>
                    <li>
                      <div></div>
                    </li>
                    <li>
                      <div></div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}


 {/* <section className="Gallery_heding_section_main">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-6">
              <h1 className="gallery">Gallery</h1>
              <p>
                Professionals may interact, invent, and flourish in a vibrant
                and motivating environment at our co-working space.
              </p>
            </div>
          </div>
        </div>
      </section>

  <div className="container-xl">
    <div className="row">
      <div className="col-12 Gallery_new">
        <div className="gallory_new_all">
        {images.map((image, index) => (
        <Panel
          key={index}
          index={index}
          backgroundImage={image}
          setActivePanel={setActivePanel}
          activePanel={activePanel}
         
        />
      ))}
        </div>
  
      </div>
    </div>
  
    </div> */}


      {/* Start testimonial section  */}
      {/* <div className="container-xl">
        <div className="row">
          <div className="col-md-8 Testmonial_section_main">
            <h1>They Satisfied with service</h1>
            <p>
              For our staff, our co-working space has changed everything. The
              contemporary amenities, adaptable workstations, and encouraging
              community have produced the perfect setting for cooperation and
              production.
            </p>
          </div>
          <div className="col-md-4">
            <div className="Testimonial_images">
              <img src="/Images/Testimonial_header_img.png" alt="images" />
            </div>
          </div>
        </div>
      </div>
      <div className="container-xl">
        <div className="row">
          <div className="col-12">
            <Swiper
              spaceBetween={30}
              slidesPerView={2}
              speed={3000}
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
                loop: true,
              }}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                100: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                620: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                720: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
              }}
              modules={[Pagination, Autoplay]}
              className="mySwiper"
            >
              <SwiperSlide className="slider_main">
                <div className="Testimonial_section_slider">
                  <div className="Testimonial_section_slider_items">
                    <p>
                      Led by visionary architects of efficiency, managed spaces
                      embody comfort, innovation, and ease—each element crafted
                      for harmony.
                    </p>

                    <div className="Testimonial_name">
                      <div className="Testimonil_images">
                        <img
                          src="/Images/Images provided by Bhaskar ji/Testimonial2.png"
                          alt="images"
                        />
                      </div>
                      <div className="Testimonial_name_heading">
                        <h5>Sukhdev Pathak</h5>
                        <p>CEO & Founder</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="slider_main">
                <div className="Testimonial_section_slider">
                  <div className="Testimonial_section_slider_items">
                    <p>
                      Driven by pioneers in efficiency, managed spaces blend
                      comfort, innovation, and ease—every detail designed for
                      perfect harmony.
                    </p>

                    <div className="Testimonial_name">
                      <div className="Testimonil_images">
                        <img
                          src="/Images/Images provided by Bhaskar ji/Testimonial.png"
                          alt="images"
                        />
                      </div>
                      <div className="Testimonial_name_heading">
                        <h5>Nirmala S. Karthik</h5>
                        <p>Manager</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="slider_main">
                <div className="Testimonial_section_slider">
                  <div className="Testimonial_section_slider_items">
                    <p>
                      Riana Towers transformed our office into a modern,
                      inspiring workspace. Their team of experts handled every
                      detail, from furniture selection to IT setup. We're more
                      productive than ever.
                    </p>

                    <div className="Testimonial_name">
                      <div className="Testimonil_images">
                        <img
                          src="/Images/Images provided by Bhaskar ji/Testimonial1.png"
                          alt="images"
                        />
                      </div>
                      <div className="Testimonial_name_heading">
                        <h5>Prisha Aggarwal</h5>
                        <p>MD & CEO</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>

      <div className="container-xl Brand_partner">
        <div className="row">
          <div className="col-md-8 Testmonial_section_main">
            <h1>Our Brand Partner</h1>
            <p>
              Committed to fostering collaboration, creativity, and
              productivity, our partner offers a diverse range of workspace
              solutions, including:
            </p>
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
      
      <section className="Slider_full_section">
        <div className="container-xl Brand_partner_slider">
          <div className="row">
            <div className="col-12">
              <div id="infinite" class="highway-slider">
                <div class="container-fluid highway-barrier">
                  <ul class="highway-lane">
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/Brand1.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/Brand2.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/brean3.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/brand4.png"
                        alt="images"
                      />
                    </li>
                   

                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/Brand1.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/Brand2.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/brean3.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/brand4.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/Brand1.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/Brand2.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/brean3.png"
                        alt="images"
                      />
                    </li>
                    <li class="highway-car">
                      <img
                        src="/Images/Images provided by Bhaskar ji/brand4.png"
                        alt="images"
                      />
                    </li>
                   
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <Footer />
    </div>
  );
};
export default Services;
